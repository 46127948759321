<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGFnutsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GFpeanutRHS from "../assets/GOODFOOD/GFNuts/GF-peanut-honey-sesame.jpg";
import GFpeanutRwS from "../assets/GOODFOOD/GFNuts/GF-peanut-rs.jpg";
import GFbraziliannut from "../assets/GOODFOOD/GFNuts/GF-brazilnut.jpg";
import GFwalnut from "../assets/GOODFOOD/GFNuts/GF-walnut.jpg";
import GFcashewRHS from "../assets/GOODFOOD/GFNuts/GF-cashew-honey-seasam.jpg";
import GFcashewR from "../assets/GOODFOOD/GFNuts/GF-cashewr.jpg";
import GFalmondR from "../assets/GOODFOOD/GFNuts/GF-almondr.jpg";
import GFsunflower from "../assets/GOODFOOD/GFNuts/GF-sunflower.jpg";
import GFpumpkin from "../assets/GOODFOOD/GFNuts/GF-pumpkin.jpg";
import GFpistachiosR from "../assets/GOODFOOD/GFNuts/GF-phistahosr.jpg";
import GFhuzelnutRHS from "../assets/GOODFOOD/GFNuts/GF-huzelnut-honey-seasam.jpg";
import GFhuzelnutR from "../assets/GOODFOOD/GFNuts/GF-huzelnutr.jpg";
import GFcashewD from "../assets/GOODFOOD/GFNuts/GF-cashewd-130.jpg";
import GFalmondD from "../assets/GOODFOOD/GFNuts/GF-almondd_130.jpg";
import GFpistachiosRS from "../assets/GOODFOOD/GFNuts/GF-phistahosrs_130.jpg";
import GFhuzelnutD from "../assets/GOODFOOD/GFNuts/GF-huzelnutd_130.jpg";
import GFpeanut from "../assets/GOODFOOD/GFNuts/GF-peanutr-nosalt.jpg";
import GFpinenut from "../assets/GOODFOOD/GFNuts/GF-pinenut.jpg";
import GFmacadamia from "../assets/GOODFOOD/GFNuts/GF-makadamia.jpg";

export default {
  name: "GoodFoodNuts",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Орехи высших сортов и сушеные семечки",
      gfGFnutsCards: [
        {
          picture: GFpeanutRHS,
          text: "GOOD FOOD Арахис жареный с мёдом и кунжутом 130 г",
          id: 1,
          description:
            "Состав: Состав: ядра арахиса жареные, кунжутное семя, сахар, мёд.<br>Продукт может содержать следы орехов.<br>Пищевая ценность в 100 г: Белки 18,1 г, Жиры 34,7 г, Углеводы 30,1 г, Пищевые волокна 2,3 г<br>Энергетическая ценность 645 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpeanutRwS,
          text: "GOOD FOOD Арахис жареный солёный 150 г",
          id: 2,
          description:
            "Состав: жареные ядра арахиса, соль поваренная пищевая, соль морская, масло растительное – смесь (масла растительные рафинированные дезодорированные: подсолнечное, пальмовый олеин, антиокислители Е319, Е330, пеногаситель Е900).<br>Продукт может содержать следы кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 28,3 г, Жиры 48,7 г, Углеводы 9,9 г, Пищевые волокна 9 г<br>Энергетическая ценность 596 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFbraziliannut,
          text: "GOOD FOOD Бразильский орех 130 г",
          id: 3,
          description:
            "Состав: ядра бразильского ореха сушеные<br>Продукт противопоказан при индивидуальной непереносимости, может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 14,3 г, Жиры 66,9 г, Углеводы 7,8 г, Пищевые волокна 8,0 г<br>Энергетическая ценность 703 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFwalnut,
          text: "GOOD FOOD Грецкий орех 130 г",
          id: 4,
          description:
            "Состав: ядра грецкого ореха сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки  14,8 г, Жиры 64,0 г, Углеводы 13,7 г, Пищевые волокна 6,0 г<br>Энергетическая ценность 698 Ккал.<br>Хранить при температуре +20°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFcashewRHS,
          text: "GOOD FOOD Кешью жареный с мёдом и кунжутом 130 г",
          id: 5,
          description:
            "Состав: ядра кешью жареные, кунжутное семя, сахар, мёд.<br>Продукт может содержать следы арахиса и других орехов.<br>Пищевая ценность в 100 г: Белки 9,9 г, Жиры 49,1 г, Углеводы 20 г<br>Энергетическая ценность 561,5 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GFcashewR,
          text: "GOOD FOOD Кешью жареный 130 г",
          id: 6,
          description:
            "Состав: ядра кешью жареные.<br>Продукт может содержать следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 26 г, Жиры 55,3 г, Углеводы 13,0 г, Пищевые волокна 2,0 г<br>Энергетическая ценность 653,7 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFalmondR,
          text: "GOOD FOOD Миндаль жареный 130 г",
          id: 7,
          description:
            "Состав: ядра миндаля жареные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 18 г, Жиры 56,9 г, Углеводы 13,2 г, Пищевые волокна 7,0 г<br>Энергетическая ценность 645 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFsunflower,
          text: "GOOD FOOD Семечки подсолнечника сушёные 150 г",
          id: 8,
          description:
            "Состав: семена подсолнечника очищенные сушеные<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 24,0 г, Жиры 47,3 г, Углеводы 16,1 г, Пищевые волокна 5,1 г<br>Энергетическая ценность 601 Ккал.<br>Хранить при температуре от + 17 0С до +23 0С относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpumpkin,
          text: "GOOD FOOD Тыквенные семечки сушёные 130 г",
          id: 9,
          description:
            "Состав: ядра семян тыквы сушеные.<br>Продукт может содержать скорлупу, фрагменты скорлупы, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 29,0 г, Жиры 46,7 г, Углеводы 13,1 г, Пищевые волокна 6 г<br>Энергетическая ценность 596 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpistachiosR,
          text: "GOOD FOOD Фисташки жареные без соли 130 г",
          id: 10,
          description:
            "Состав: фисташки в скорлупе жареные.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 28,58 г, Жиры 51,6 г, Углеводы 16,4 г<br>Энергетическая ценность 642 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFhuzelnutR,
          text: "GOOD FOOD Фундук жареный 130 г",
          id: 11,
          description:
            "Состав: ядра фундука жареные, масло подсолнечное, комплексная пищевая добавка «Гриндокс» (агент влагоудерживающий Е1520, антиоксилители (Е319, Е330)).<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 18 г, Жиры 56,7 г, Углеводы 13,7 г, Пищевые волокна 6,0 г<br>Энергетическая ценность 632 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFhuzelnutRHS,
          text: "GOOD FOOD Фундук жареный с мёдом и кунжутом 130 г",
          id: 12,
          description:
            "Состав: ядра фундука жареные, кунжутное семя, сахар, мёд.<br>Продукт может содержать следы арахиса и других орехов.<br>Пищевая ценность в 100 г: Белки 13,5 г, Жиры 48,7 г, Углеводы 26,6 г<br>Энергетическая ценность 598,7 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GFcashewD,
          text: "GOOD FOOD Кешью сушеный 130 г",
          id: 13,
          description:
            "Состав: ядра кешью сушеные.<br>Продукт может содержать следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 17,5 г, Жиры 42,2 г, Углеводы 30,5 г<br>Энергетическая ценность 572 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFalmondD,
          text: "GOOD FOOD Миндаль сушеный 130 г",
          id: 14,
          description:
            "Состав: ядра миндаля сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 18,6 г, Жиры 57,7 г, Углеводы 13,6 г<br>Энергетическая ценность 645 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpistachiosRS,
          text: "GOOD FOOD Фисташки жареные соленые 130 г",
          id: 15,
          description:
            "Состав: фисташки в скорлупе жареные, соль морская, регулятор кислотности – кислота лимонная.<br>Продукт может содержать следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 28,58 г, Жиры 51,6 г, Углеводы 16,4 г<br>Энергетическая ценность 642 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFhuzelnutD,
          text: "GOOD FOOD Фундук сушеный 130 г",
          id: 16,
          description:
            "Состав: ядра фундука сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 12,6 г, Жиры 62,4 г, Углеводы 13,7 г<br>Энергетическая ценность 679 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpeanut,
          text: "GOOD FOOD Арахис жареный без соли 150 г",
          id: 17,
          description:
            "Состав: жареные ядра арахиса.<br>Продукт может содержать следы кунжута и орехов.<br>Пищевая ценность в 100 г: Белки 29 г, Жиры 49,7 г, Углеводы 10,1 г.<br>Энергетическая ценность 621,7 Ккал.<br>Хранить при температуре +20°С±3°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>ТУ 9146-006-60458573-14<br>Страна изготовитель: Россия",
        },
        {
          picture: GFpinenut,
          text: "GOOD FOOD Кедровый орех 130 г",
          id: 18,
          description:
            "Состав: ядра кедрового ореха сушеные.<br>Продукт может содержать косточки, фрагменты косточек, следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки 23,7 г, Жиры 60,0 г, Углеводы 12,5 г<br>Энергетическая ценность 692,2 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 6 месяцев.<br>9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFmacadamia,
          text: "GOOD FOOD Макадамия 130 г",
          id: 19,
          description:
            "Состав: ядра ореха макадамии сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 8,0 г, Жиры 73,8 г, Углеводы 13,8 г, Пищевые волокна 6,4 г<br>Энергетическая ценность 702 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 70%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
